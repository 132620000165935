import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/layout.vue'),
    redirect: { name: 'login' },
    children: [
      { path: 'auth', name: 'auth', meta: { public: true }, component: () => import('pages/auth/index') },
      {
        path: 'auth/error',
        name: 'auth-error',
        meta: { public: true },
        component: () => import('pages/auth/error.vue'),
      },
      { path: 'login', name: 'login', meta: { public: true }, component: () => import('pages/login.vue') },
      {
        path: 'login/token',
        name: 'login-token',
        meta: { public: true },
        component: () => import('pages/login-token.vue'),
      },
      { path: 'orders', name: 'orders', redirect: { name: 'dashboard' } },
      { path: 'orders/:id', name: 'order', component: () => import('pages/orders/[id].vue') },
      { path: 'dashboard', name: 'dashboard', component: () => import('pages/dashboard.vue') },
      { path: 'forms', name: 'forms', component: () => import('pages/forms.vue') },
      { path: 'companies', name: 'companies', component: () => import('pages/companies/index.vue') },
      {
        path: 'companies/:id',
        name: 'company-wrapper',
        component: () => import('pages/companies/[id]/route.vue'),
        children: [
          { path: '', name: 'company', component: () => import('pages/companies/[id]/index.vue') },
          {
            path: 'blacklist',
            name: 'company-blacklist',
            component: () => import('pages/companies/[id]/blacklist.vue'),
          },
        ],
      },
      { path: 'merchants', name: 'merchants', component: () => import('pages/merchants/index.vue') },
      { path: 'settings', name: 'settings', component: () => import('pages/settings/index.vue') },
      { path: 'checks', name: 'checks', component: () => import('pages/checks/index.vue') },
      { path: 'checks/:id', name: 'check', component: () => import('pages/checks/[id].vue') },
      { path: 'checks/export', name: 'checks-export', component: () => import('pages/checks/export.vue') },
      { path: 'sentry', name: 'sentry-test', component: () => import('pages/sentry.vue') },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/error.vue'),
  },
]

export default routes
