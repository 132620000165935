/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import {LocalStorage,Notify,LoadingBar} from 'quasar'



export default { config: {"brand":{"primary":"#263acf","secondary":"#bd91ec","accent":"#bd91ec","dark":"#1d1d1d","dark-page":"#121212","positive":"#3fb76f","negative":"#ed6f6f","info":"#00aaf3","warning":"#eeb61e"}},plugins: {LocalStorage,Notify,LoadingBar} }

